var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "settings_commuting_details" }
    },
    [
      _c("inner-page-title", {
        attrs: { title: "Commuting details", action: "Save" },
        on: { actionClicked: _vm.onSaveProfile }
      }),
      _c(
        "div",
        { staticClass: "container", staticStyle: { "max-width": "600px" } },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Address" } },
                        [
                          _c(
                            "el-input",
                            {
                              class: {
                                w100: true,
                                "validate-success": _vm.info.address_validated
                              },
                              attrs: { placeholder: "Your address" },
                              on: { change: _vm.validateAddress },
                              model: {
                                value: _vm.user.address.address_formatted,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.user.address,
                                    "address_formatted",
                                    $$v
                                  )
                                },
                                expression: "user.address.address_formatted"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    icon: _vm.info.address_validated
                                      ? "el-icon-check"
                                      : ""
                                  },
                                  on: { click: _vm.validateAddress },
                                  slot: "append"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.info.address_validated
                                        ? "Verified"
                                        : "Verify"
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Office" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "Office" },
                              model: {
                                value: _vm.user.office,
                                callback: function($$v) {
                                  _vm.$set(_vm.user, "office", $$v)
                                },
                                expression: "user.office"
                              }
                            },
                            _vm._l(_vm.locations, function(location) {
                              return _c("el-option", {
                                key: location.id,
                                attrs: {
                                  label: location.data.name,
                                  value: location.id
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c("form-item", { attrs: { label: "Commuting days" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "fancy-checkboxes",
                            staticStyle: { "margin-top": "2px" }
                          },
                          [
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.user.commuting_days_of_week.monday,
                                    expression:
                                      "user.commuting_days_of_week.monday"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "days_of_week_checkbox_monday"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.user.commuting_days_of_week.monday
                                  )
                                    ? _vm._i(
                                        _vm.user.commuting_days_of_week.monday,
                                        null
                                      ) > -1
                                    : _vm.user.commuting_days_of_week.monday
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.user.commuting_days_of_week.monday,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "monday",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "monday",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.user.commuting_days_of_week,
                                        "monday",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  attrs: { for: "days_of_week_checkbox_monday" }
                                },
                                [_vm._v("Monday")]
                              )
                            ]),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.user.commuting_days_of_week.tuesday,
                                    expression:
                                      "user.commuting_days_of_week.tuesday"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "days_of_week_checkbox_tuesday"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.user.commuting_days_of_week.tuesday
                                  )
                                    ? _vm._i(
                                        _vm.user.commuting_days_of_week.tuesday,
                                        null
                                      ) > -1
                                    : _vm.user.commuting_days_of_week.tuesday
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.user.commuting_days_of_week.tuesday,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "tuesday",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "tuesday",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.user.commuting_days_of_week,
                                        "tuesday",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "days_of_week_checkbox_tuesday"
                                  }
                                },
                                [_vm._v("Tuesday")]
                              )
                            ]),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.user.commuting_days_of_week.wednesday,
                                    expression:
                                      "user.commuting_days_of_week.wednesday"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "days_of_week_checkbox_wednesday"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.user.commuting_days_of_week.wednesday
                                  )
                                    ? _vm._i(
                                        _vm.user.commuting_days_of_week
                                          .wednesday,
                                        null
                                      ) > -1
                                    : _vm.user.commuting_days_of_week.wednesday
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.user.commuting_days_of_week
                                          .wednesday,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "wednesday",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "wednesday",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.user.commuting_days_of_week,
                                        "wednesday",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "days_of_week_checkbox_wednesday"
                                  }
                                },
                                [_vm._v("Wednesday")]
                              )
                            ]),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.user.commuting_days_of_week.thursday,
                                    expression:
                                      "user.commuting_days_of_week.thursday"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "days_of_week_checkbox_thursday"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.user.commuting_days_of_week.thursday
                                  )
                                    ? _vm._i(
                                        _vm.user.commuting_days_of_week
                                          .thursday,
                                        null
                                      ) > -1
                                    : _vm.user.commuting_days_of_week.thursday
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.user.commuting_days_of_week
                                          .thursday,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "thursday",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "thursday",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.user.commuting_days_of_week,
                                        "thursday",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "days_of_week_checkbox_thursday"
                                  }
                                },
                                [_vm._v("Thursday")]
                              )
                            ]),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.user.commuting_days_of_week.friday,
                                    expression:
                                      "user.commuting_days_of_week.friday"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "days_of_week_checkbox_friday"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.user.commuting_days_of_week.friday
                                  )
                                    ? _vm._i(
                                        _vm.user.commuting_days_of_week.friday,
                                        null
                                      ) > -1
                                    : _vm.user.commuting_days_of_week.friday
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.user.commuting_days_of_week.friday,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "friday",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "friday",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.user.commuting_days_of_week,
                                        "friday",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  attrs: { for: "days_of_week_checkbox_friday" }
                                },
                                [_vm._v("Friday")]
                              )
                            ]),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.user.commuting_days_of_week.saturday,
                                    expression:
                                      "user.commuting_days_of_week.saturday"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "days_of_week_checkbox_saturday"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.user.commuting_days_of_week.saturday
                                  )
                                    ? _vm._i(
                                        _vm.user.commuting_days_of_week
                                          .saturday,
                                        null
                                      ) > -1
                                    : _vm.user.commuting_days_of_week.saturday
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.user.commuting_days_of_week
                                          .saturday,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "saturday",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "saturday",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.user.commuting_days_of_week,
                                        "saturday",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  attrs: {
                                    for: "days_of_week_checkbox_saturday"
                                  }
                                },
                                [_vm._v("Saturday")]
                              )
                            ]),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.user.commuting_days_of_week.sunday,
                                    expression:
                                      "user.commuting_days_of_week.sunday"
                                  }
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "days_of_week_checkbox_sunday"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.user.commuting_days_of_week.sunday
                                  )
                                    ? _vm._i(
                                        _vm.user.commuting_days_of_week.sunday,
                                        null
                                      ) > -1
                                    : _vm.user.commuting_days_of_week.sunday
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.user.commuting_days_of_week.sunday,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "sunday",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.user.commuting_days_of_week,
                                            "sunday",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.user.commuting_days_of_week,
                                        "sunday",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  attrs: { for: "days_of_week_checkbox_sunday" }
                                },
                                [_vm._v("Sunday")]
                              )
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { sm: 11 }
                    },
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Time leaving home" } },
                        [
                          _c("el-time-select", {
                            staticClass: "w100",
                            attrs: {
                              placeholder: "Time leaving home",
                              "picker-options": {
                                start: "05:30",
                                step: "00:15",
                                end: "15:00"
                              }
                            },
                            model: {
                              value: _vm.info.commuting_times.go_leaving_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.info.commuting_times,
                                  "go_leaving_time",
                                  $$v
                                )
                              },
                              expression: "info.commuting_times.go_leaving_time"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 13 } },
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Trip duration" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "w100",
                              attrs: {
                                type: "number",
                                placeholder: "Trip duration"
                              },
                              model: {
                                value:
                                  _vm.info.commuting_times.go_commute_duration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.info.commuting_times,
                                    "go_commute_duration",
                                    $$v
                                  )
                                },
                                expression:
                                  "info.commuting_times.go_commute_duration"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("minutes")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: { sm: 11 }
                    },
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Time leaving work" } },
                        [
                          _c("el-time-select", {
                            staticClass: "w100",
                            attrs: {
                              placeholder: "Time leaving work",
                              "picker-options": {
                                start: "12:00",
                                step: "00:15",
                                end: "23:45"
                              }
                            },
                            model: {
                              value: _vm.info.commuting_times.back_leaving_time,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.info.commuting_times,
                                  "back_leaving_time",
                                  $$v
                                )
                              },
                              expression:
                                "info.commuting_times.back_leaving_time"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { sm: 13 } },
                    [
                      _c(
                        "form-item",
                        { attrs: { label: "Trip duration" } },
                        [
                          _c(
                            "el-input",
                            {
                              staticClass: "w100",
                              attrs: {
                                type: "number",
                                placeholder: "Trip duration"
                              },
                              model: {
                                value:
                                  _vm.info.commuting_times
                                    .back_commute_duration,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.info.commuting_times,
                                    "back_commute_duration",
                                    $$v
                                  )
                                },
                                expression:
                                  "info.commuting_times.back_commute_duration"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v("minutes")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }