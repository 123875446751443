<template>
  <div v-loading.fullscreen.lock="loading" id="settings_commuting_details">
		<inner-page-title title="Commuting details" action="Save" @actionClicked="onSaveProfile"></inner-page-title>
		<div style="max-width: 600px" class="container">
			<div>
				<el-row :gutter="20">
					<el-col>
						<form-item label="Address">
							<el-input @change="validateAddress" :class="{w100: true, 'validate-success': info.address_validated }" placeholder="Your address" v-model="user.address.address_formatted">
								<el-button @click="validateAddress" type="primary" :icon="info.address_validated ? 'el-icon-check' : ''" slot="append">{{ info.address_validated ? 'Verified' : 'Verify' }}</el-button>
							</el-input>
						</form-item>
					</el-col>

					<el-col>
						<form-item label="Office">
							<el-select v-model="user.office" placeholder="Office">
								<el-option
									v-for="location in locations"
									:key="location.id"
									:label="location.data.name"
									:value="location.id">
								</el-option>
							</el-select>
						</form-item>
					</el-col>

					<el-col>
						<form-item label="Commuting days">
							<div style="margin-top: 2px" class="fancy-checkboxes">
								<div>
									<input type="checkbox" id="days_of_week_checkbox_monday" v-model="user.commuting_days_of_week.monday">
									<label for="days_of_week_checkbox_monday">Monday</label>
								</div>

								<div>
									<input type="checkbox" id="days_of_week_checkbox_tuesday" v-model="user.commuting_days_of_week.tuesday">
									<label for="days_of_week_checkbox_tuesday">Tuesday</label>
								</div>

								<div>
									<input type="checkbox" id="days_of_week_checkbox_wednesday" v-model="user.commuting_days_of_week.wednesday">
									<label for="days_of_week_checkbox_wednesday">Wednesday</label>
								</div>

								<div>
									<input type="checkbox" id="days_of_week_checkbox_thursday" v-model="user.commuting_days_of_week.thursday">
									<label for="days_of_week_checkbox_thursday">Thursday</label>
								</div>

								<div>
									<input type="checkbox" id="days_of_week_checkbox_friday" v-model="user.commuting_days_of_week.friday">
									<label for="days_of_week_checkbox_friday">Friday</label>
								</div>

								<div>
									<input type="checkbox" id="days_of_week_checkbox_saturday" v-model="user.commuting_days_of_week.saturday">
									<label for="days_of_week_checkbox_saturday">Saturday</label>
								</div>

								<div>
									<input type="checkbox" id="days_of_week_checkbox_sunday" v-model="user.commuting_days_of_week.sunday">
									<label for="days_of_week_checkbox_sunday">Sunday</label>
								</div>
							</div>
						</form-item>
					</el-col>

					<el-col :sm="11" style="margin-bottom: 10px">
						<form-item label="Time leaving home">
							<el-time-select
								class="w100"
								placeholder="Time leaving home"
								v-model="info.commuting_times.go_leaving_time"
								:picker-options="{
									start: '05:30',
									step: '00:15',
									end: '15:00'
								}"></el-time-select>
						</form-item>
					</el-col>
					<el-col :sm="13">
						<form-item label="Trip duration">
							<el-input class="w100" type="number" placeholder="Trip duration" v-model="info.commuting_times.go_commute_duration">
								<template slot="append">minutes</template>
							</el-input>
						</form-item>
					</el-col>

					<el-col :sm="11" style="margin-bottom: 10px">
						<form-item label="Time leaving work">
							<el-time-select
								class="w100"
								placeholder="Time leaving work"
								v-model="info.commuting_times.back_leaving_time"
								:picker-options="{
									start: '12:00',
									step: '00:15',
									end: '23:45'
								}"></el-time-select>
						</form-item>
					</el-col>
					<el-col :sm="13">
						<form-item label="Trip duration">
							<el-input class="w100" type="number" placeholder="Trip duration" v-model="info.commuting_times.back_commute_duration">
								<template slot="append">minutes</template>
							</el-input>
						</form-item>
					</el-col>
				</el-row>
			</div>
		</div>
  </div>
</template>

<script>
import FormItem from '@/components/Form/FormItem';
import firebase from '@/firebase';
import lodash from 'lodash';
import moment from 'moment';

export default {
	components: { FormItem },
	data() {
		return {
			user: { address: {} },
			loading: false,
			info: {
				address_validated: false,
				commuting_times: {
					go_leaving_time: '',
					back_leaving_time: '',
					go_commute_duration: 0,
					back_commute_duration: 0
				}
			}
		}
	},

	methods: {
		onSaveProfile() {
			let form_data = {
				address: this.user.address,
				office: this.user.office,
				commuting_days_of_week: this.user.commuting_days_of_week,
				commuting_times: {
					go_leaving_time: moment(this.info.commuting_times.go_leaving_time, 'HH:mm').toISOString(),
					go_arriving_time: moment(this.info.commuting_times.go_leaving_time, 'HH:mm').add(this.info.commuting_times.go_commute_duration, 'minutes').toISOString(),
					back_leaving_time: moment(this.info.commuting_times.back_leaving_time, 'HH:mm').toISOString(),
					back_arriving_time: moment(this.info.commuting_times.back_leaving_time, 'HH:mm').add(this.info.commuting_times.back_commute_duration, 'minutes').toISOString()
				}
			};

			this.loading = true;

			// Update carpooling details
			this.SharoAPI({ action: 'updateUserProfile', data: form_data }).then(result => {
				console.log(result);

				if (result.data.error) {
					return this.$message({ type: 'error', showClose: true, message: result.data.error_message });
				}

				this.$message({ type: 'success', showClose: true, message: 'Profile updated successfully' });
			}).finally(() => this.loading = false);
		},

		async validateAddress() {
			let response = await this.geocode(this.user.address.address_formatted);
			
			if (response.results.length < 1) {
				this.info.address.validated = false;
				return this.$message({ type: 'error', showClose: true, message: 'Cannot find address, please try again'});
			}

			this.info.address_validated = true;
			this.user.address.address_formatted = response.results[0].formatted_address;
			this.user.address.address_location.lat = response.results[0].geometry.location.lat;
			this.user.address.address_location.lng = response.results[0].geometry.location.lng;
			this.user.address.address_place_id = response.results[0].place_id;
		}
	},

	computed: {
		_user() {
			return this.$store.getters.user;
		},

		locations() {
			if (!this.$store.getters.organization || !this.$store.getters.organization.data || !this.$store.getters.organization.data.locations) {
				return [ { id: null, data: { name: 'All '} }];
			}

			return Object.keys(this.$store.getters.organization.data.locations).map(val => { return { id: val, data: this.$store.getters.organization.data.locations[val]}});
		}
	},

	watch: {
		_user: {
			handler(val) {
				this.user = lodash.cloneDeep(val.data || { address: {}});
				this.info.commuting_times.go_leaving_time = moment(val.data.commuting_times && val.data.commuting_times.go_leaving_time || '').format('HH:mm');
				this.info.commuting_times.back_leaving_time = moment(val.data.commuting_times && val.data.commuting_times.back_leaving_time || '').format('HH:mm');
				this.info.commuting_times.go_commute_duration = Math.round(Number(moment(val.data.commuting_times && val.data.commuting_times.go_arriving_time || '').diff(moment(val.data.commuting_times && val.data.commuting_times.go_leaving_time || '')))/1000/60);
				this.info.commuting_times.back_commute_duration = Math.round(Number(moment(val.data.commuting_times && val.data.commuting_times.back_arriving_time || '').diff(moment(val.data.commuting_times && val.data.commuting_times.back_leaving_time || '')))/1000/60);
			},
			immediate: true
		}
	},

	async mounted() {
		
	}
}
</script>

<style lang="scss" scoped>
	#settings_commuting_details {
		.form-item {
			margin-bottom: 0;
		}

		.profile-image-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;

			img {
				border-radius: 50%;
				width: 100%;
				max-width: 120px;
				opacity: 0.3;
			}

			.upload-image {
				position: absolute;
			}
		}
	}
</style>